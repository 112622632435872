import React from "react"

class App extends React.Component{
    render(){
        return(
            <div>
                {/*<h1>My A Level Project</h1>*/}
                <div id="game">
                    <div id="game1"></div>
                    <div id="game2"></div>
                </div>
            </div>
        )
    }
}

export default App